<template>
  <div class="subscription-popup">
    <Popup
      v-if="locked"
      :modal-id="modalId"
      :v-center="true"
      custom-class="subscription-modules"
    >
      <template v-slot:body>
        <div class="subscription container">
          <div class="recht-row row">
            <div class="recht-col col">
              <div class="recht-image">
                <img
                  class="recht-police"
                  src="@/assets/images/girl_police_select_sub.svg"
                  alt="image"
                />
              </div>
            </div>
            <div class="recht-col col">
              <div class="recht-subscription">
                <h2 class="recht-label">
                  {{ subscription.label }}
                </h2>
                <font-awesome-icon
                  class="fa-plus"
                  icon="times"
                  data-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div class="recht-description card">
                <h5 class="recht-title">
                  {{ subscription.title }}
                </h5>
                <div class="recht-content d-flex justify-content-between">
                  <div class="recht-content-col">
                    <h5 class="recht-access">
                      {{ subscription.access.label }}:
                    </h5>
                    <ul class="recht-list">
                      <li
                        class="recht-item"
                        v-for="(item, index) in subscription.access.items"
                        :key="index"
                      >
                        {{ item }}
                      </li>
                    </ul>
                  </div>
                  <div class="recht-price">
                    <h1 class="recht-amount">
                      {{ subscription.access.price }}
                    </h1>
                    <label class="recht-month">{{
                      subscription.access.month
                    }}</label>
                  </div>
                </div>

                <Button
                  class="btn-custom btn-slateblue"
                  :label="labels.click_here_for_details"
                  border-radius="0px"
                  padding="11px 30px"
                  font-size="15px"
                  font-weight="600"
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Popup>
  </div>
</template>

<script>
import Popup from "@/components/Popups/Popup";
import Button from "@/components/Buttons/Button.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { mapGetters } from "vuex";

library.add(faTimes);

export default {
  name: "SubscriptionPopup",
  components: { Popup, Button },
  props: {
    locked: {
      type: Boolean,
      default: true,
    },
    modalId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      subscription: {
        label: "Abon auswählen",
        title: "Polizeivollzugsdienst NRW",
        access: {
          label: "Dein Vollzugriff",
          price: "€14.98",
          month: "Pro monat",
          items: [
            "Alle Rechtsfächer",
            "Kurz und prägnant",
            "Klausurenkurs",
            "Der Gutachtenstil - erläutert",
            "Unzählige Beispielsfälle...",
          ],
        },
      },
    };
  },
  computed: {
    ...mapGetters("helper", ["labels"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.subscription-modules {
  ::v-deep {
    .modal-body {
      width: auto;
      height: auto;
      padding: 16px;
      color: $black;
      @include transition--ease-out;

      @media (min-width: 992px) {
        width: 798px;
        height: 423px;
      }

      .subscription {
        .recht-row {
          .recht-col:first-child {
            padding: 0px;
            width: calc(45% - 30px);
            .recht-image {
              .recht-police {
                width: calc(100% - 50px);
                height: auto;
                margin-top: 25px;
                object-fit: contain;
                object-position: center;
              }
            }

            @media (max-width: 991.98px) {
              display: none;
            }
          }

          .recht-col:last-child {
            padding: 0px;
            width: calc(55% - 30px);

            .recht-subscription {
              display: flex;
              justify-content: space-between;

              .recht-label {
                font-weight: 700;
                font-size: 1.875rem;
              }

              .fa-plus {
                width: 20px;
                padding: 3px;
                height: 20px;
                cursor: pointer;
                border-radius: 50%;
                background: $background;
              }
            }

            .recht-description {
              border: none;
              padding: 15px;
              overflow-y: auto;
              border-radius: 5px;
              box-shadow: 2px 7px 46px rgb(0 0 0 / 9%);
              max-height: 345px;

              .recht-content {
                display: flex;

                &-col {
                  padding: 0px;

                  .recht-list {
                    padding: 0px 20px;
                  }
                }

                .recht-price {
                  width: 128px;
                  height: 128px;
                  display: flex;
                  border-radius: 2px;
                  align-items: center;
                  flex-direction: column;
                  justify-content: center;
                  background-color: $whitesmoke;

                  .recht-amount {
                    font-weight: 700;
                    color: $focus_color;
                    font-size: 1.563rem;
                  }
                  .recht-month {
                    font-weight: 500;
                  }
                }
              }

              &::-webkit-scrollbar {
                width: $scrollbar_width;
              }

              &::-webkit-scrollbar-track {
                background: $light_gray;
              }

              &::-webkit-scrollbar-thumb {
                background-color: $focus_color;
              }
              .recht-title,
              .recht-access {
                font-weight: 700;
                font-size: 1.25rem;
                margin-bottom: 15px;
              }

              .btn-custom {
                text-transform: uppercase;
                border: 2px solid $focus_color;
                box-shadow: 0px 10px 30px rgb(98 88 241 / 37%);

                &:hover,
                &:focus {
                  color: $focus_color !important;
                  background: transparent !important;
                }
              }
            }
          }
        }
      }
    }
    .modal-content {
      border-radius: 0.3rem;
    }
    .modal-header,
    .modal-footer {
      display: none;
    }
  }
}
</style>
