<template>
  <div class="wrapper">
    <div class="outside-title" v-if="outsideTitle">{{ outsideTitle }}</div>
    <div class="list-wrapper">
      <div class="list-items">
        <a
          v-if="title"
          class="items-title"
          data-toggle="collapse"
          href="#user-topic-collapse"
          data-target="#items-list"
          aria-expanded="false"
          aria-controls="items-list"
          @click="collapsed = !collapsed"
        >
          {{ title }}
          <font-awesome-icon
            :class="{ animate_collapsed: !collapsed, animate_open: collapsed }"
            class="primary-icon"
            icon="chevron-up"
          />
        </a>
        <div :class="['collapse', { show: expanded }]" id="items-list">
          <div class="item">
            <ul>
              <li v-for="(item, index) in dataList" :key="index">
                <a :href="item.link">
                  {{ item.text }}
                  <font-awesome-icon icon="chevron-right" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronUp, faChevronRight } from "@fortawesome/free-solid-svg-icons";
library.add(faChevronUp, faChevronRight);

export default {
  name: "CollapsibleList",
  data() {
    return {
      collapsed: true,
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    outsideTitle: {
      type: String,
      default: "",
    },
    dataList: {
      type: Array,
      default: () => [],
    },
    expanded: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.wrapper {
  .outside-title {
    font-size: 1.75rem;
  }

  .list-wrapper {
    box-sizing: border-box;

    .list-items {
      margin-bottom: 30px;
      box-shadow: -2px 0px 15px -2px #0000002e;
      border-radius: 5px;
      background: $white;
      overflow: hidden;
      padding: 0;
      margin: 0;
      font-size: 16px;
      color: $black;
      font-weight: 400;
      line-height: 1.5;
      text-align: left;

      svg {
        color: $darkgray;
      }

      // .items-title {
      //     @media screen and (max-width: 992px) {
      //         display: none;
      //     }
      // }

      a {
        padding: 15px 25px;
        color: $black;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        word-break: break-word;
        background-color: transparent;
        cursor: pointer;

        .primary-icon {
          width: 20px;
          height: 20px;
        }

        .animate_collapsed {
          transform: rotate(-180deg);
          transition: all 0.4s ease;
        }

        .animate_open {
          transition: all 0.4s ease;
        }
      }

      ul {
        padding: 0;
        margin: 0;
        li {
          font-size: 15px;
          list-style-type: none;
          border-top: 1px solid $lightgray;
          a {
            padding: 15px 25px 15px 25px;
            background: $white;
            font-weight: 500;
            color: #414141;
            position: relative;
            text-decoration: none;

            &:hover {
              background: $focus_color;
              color: $white;
              text-decoration: none;
              transition: all 0.3s ease;

              svg {
                color: $white;
              }
            }
          }
        }
      }
    }
  }
  h3 {
    display: none;
    font-family: "Oswald", sans-serif;
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    @media screen and (max-width: 992px) {
      display: block !important;
    }
  }
}
</style>
