<template>
    <BaseCard class="newsletter container" padding="1rem">
        <div class="newsletter-content">
            <div class="title">{{ title }}</div>
            <div class="mj-embedded-body">
                <div class="paragraph">{{ paragraph }}</div>
                <b class="bachelor">{{ bachelor }}</b>
                <div class="hashtag">
                    <span v-for="(hashtag, index) in hashtags" :key="index">{{ hashtag }}</span>
                </div>
            </div>
        </div>
        <div class="newsletter-inputs">
            <FormulateInput
                type="text"
                name="email"
                v-model="email"
                error-behavior="live"
                id="formulate-input--text"
                :placeholder="emailPlaceholder"
            ></FormulateInput>
            <FormulateInput
                v-model="letter"
                type="checkbox"
                :label="newsletter"
                name="newsletter"
                id="formulate-input--checkbox"
            />
            <Button
                class="btn-slateblue"
                label="Jetzt mitmachen!"
                width="235px"
                height="36px"
                padding="auto"
            ></Button>
        </div>
    </BaseCard>
</template>

<script>
import BaseCard from "@/components/Cards/BaseCard";
import Button from "@/components/Buttons/Button";
export default {
    name: "NewsletterForm",
    components: { BaseCard, Button },
    props: {
        newsletter: {
            type: String,
            default:
                "Ich stimme dem Erhalt des Newsletters zu. Mir ist bekannt, dass ich ihn jederzeit abbestellen kann.",
        },
        title: {
            title: String,
            default: "Title",
        },
        paragraph: {
            title: String,
            default: "Paragraph",
        },
        bachelor: {
            title: String,
            default: "Bachelor",
        },
        hashtags: {
            type: Array,
            default: () => {},
        },
        emailPlaceholder: {
            type: String,
            default: "Deine E-Mail-Adresse",
        },
    },
    data() {
        return {
            email: "",
            letter: "",
        };
    },
};
</script>

<style lang="scss" scoped>

.newsletter {
    text-align: center;
    max-width: 727px;
    
    &.base-card {
        box-shadow: 0px 0px 20px rgb(0 0 0 / 35%);
    }

    .newsletter-content {
        .title {
            color: $focus_color;
            font-size: 1.625rem;
            font-weight: 500;
        }

        .mj-embedded-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            box-sizing: border-box;
            color: rgb(176, 179, 184);
            font-size: 16px;
            padding: 25px 0px;
            text-align: center;

            .paragraph {
                margin-bottom: 1rem;
            }

            .bachelor {
                font-weight: 700;
                margin-bottom: 1rem;
            }
            .hashtag {
                margin-right: 1rem;
                
                span {
                    &:not(:last-child) {
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    .newsletter-inputs {
        ::v-deep {
            .formulate-input {

                &[data-type="checkbox"] {
                    margin-bottom: 40px;

                    .formulate-input-element--checkbox {
                        flex: 0 0 16px;
                    }

                    label {
                        font-weight: 500;
                    }
                }
                &-element {
                    max-width: 100%;

                    #formulate-input--text {
                        font-size: 1rem;
                        border-radius: 3px;
                        padding: 8px 16px 8px 16px;
                        outline: none;

                        &:focus {
                            border-color: $lightgray;
                        }
                    }

                    .formulate-input-element-decorator {
                        margin: 0px;
                    }
                }
            }
        }
    }
}
</style>
